import Link from 'next/link';
import { FunctionComponent, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { CalendarEvent } from '../../lib/api/types/calendar-event';

interface Props {
  calendarEvent: CalendarEvent;
}

const EventCard: FunctionComponent<Props> = ({ calendarEvent }) => {
  const [isEventOrganizer, setIsEventOrganizer] = useState(false);

  useEffect(() => {
    if (calendarEvent?.category?.slug === 'angebote-fuer-veranstalter') {
      setIsEventOrganizer(true);
    }
  }, [calendarEvent?.category?.slug]);

  return (
    <>
      <Link href={`/veranstaltungen/${calendarEvent.id}`}>
        <div className="bg-white overflow-hidden shadow rounded-md sm:rounded mb-4 cursor-pointer">
          <div className="px-4 py-4">
            <div className="bg-white py-3 sm:px-4">
              <div className="flex space-x-3">
                <div className="min-w-0 flex-1">
                  <div className="flex justify-end mb-2">
                    {!isEventOrganizer ? (
                      <>
                        {!!calendarEvent.additionalDates &&
                          !calendarEvent.recurring && (
                            <div className="bg-primary text-white px-2 py-1 mr-4 rounded-md text-sm md:text-normal opacity-75">
                              Mehrtägig
                            </div>
                          )}
                      </>
                    ) : null}

                    <div className="bg-primary text-white px-2 py-1 rounded-md text-sm md:text-normal">
                      {calendarEvent?.ageRange?.replace(/\|/g, ' / ')}
                    </div>
                  </div>
                  {!isEventOrganizer ? (
                    <>
                      <div className="flex justify-between">
                        <h2 className="font-medium text-gray-500 text-sm">
                          {calendarEvent.recurring
                            ? ''
                            : calendarEvent.formattedDate}
                        </h2>
                      </div>
                    </>
                  ) : null}
                  <h2
                    className="text-2xl font-medium text-primary mb-4"
                    id="test-event-title"
                  >
                    {calendarEvent.title}
                  </h2>
                  {isEventOrganizer ? (
                    <div
                      className="text-gray-700 text-lg mt-2 font-light event-card"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          calendarEvent.description.length > 200
                            ? `${calendarEvent.description?.substring(
                                0,
                                200
                              )}...`
                            : calendarEvent.description
                        ),
                      }}
                    />
                  ) : (
                    <div
                      className="text-gray-700 text-lg mt-2 font-light event-card"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(calendarEvent.description),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default EventCard;
