import { FilterIcon, XIcon } from '@heroicons/react/solid';
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BannerAd } from '../lib/api/types/banner-ad';
import { CalendarEvent } from '../lib/api/types/calendar-event';
import { handleFilters } from '../lib/utility/filter';
import { selectBannerAds } from '../lib/utility/select-banner-ads';
import AppContext from './common/AppContext';
import FilterSection from './FilterSection';
import BannerAdComponent from './shared/BannerAd';
import EventCard from './shared/Card';
import GlobalBanner from './shared/GlobalBanner';

interface Props {
  title?: string;
  bannerAds: BannerAd[];
  calendarEvents: CalendarEvent[];
}

const EventMainSection: FunctionComponent<Props> = ({
  bannerAds,
  calendarEvents,
  title,
}) => {
  const [selectedAds, setSelectedAds] = useState<BannerAd[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>(calendarEvents);

  const {
    calendarEventFilter,
    resetCalendarEventFilter,
    filterIsActive,
    setFilteredEvents,
  } = useContext(AppContext);

  useEffect(() => {
    resetCalendarEventFilter();
    document.title = `TAGEO — ${title || 'Veranstaltungen'}`;
  }, []);

  useEffect(() => {
    const filtered = handleFilters(calendarEventFilter, calendarEvents);
    setEvents(filtered);
  }, [calendarEventFilter, calendarEvents]);

  useEffect(() => {
    const selectedAds = selectBannerAds(bannerAds, events.length);

    setSelectedAds(selectedAds);
    setFilteredEvents(events);
  }, [bannerAds, events, setFilteredEvents]);

  const mapCalendarEventsWithBanners = (
    bannerAds: BannerAd[],
    calendarEvents: CalendarEvent[]
  ) => {
    if (!calendarEvents.length) {
      return (
        <div className="text-lg text-gray-800">
          Keine Veranstaltungen gefunden.
        </div>
      );
    }
    const ads = [...bannerAds];

    return calendarEvents.map((calendarEvent, index) => {
      const ratio = Number.parseInt(process.env.EVENT_TO_AD_RATIO || '4');

      if (index > 0 && ads.length && index % ratio === 0) {
        return (
          <Fragment key={calendarEvent.id}>
            <EventCard calendarEvent={calendarEvent} />
            <BannerAdComponent bannerAd={ads.pop()} />
          </Fragment>
        );
      }

      return <EventCard key={calendarEvent.id} calendarEvent={calendarEvent} />;
    });
  };

  return (
    <>
      <div className="my-4 min-w-[50%] max-w-6xl px-4 sm:px-6 lg:px-8">
        <FilterSection />

        <div className="z-0 flex items-center text-3xl font-md mt-2 mb-6 text-gray-800">
          <h1 className="mr-4">{title || 'Kursübersicht'}</h1>
          {filterIsActive ? (
            <button
              onClick={() => resetCalendarEventFilter()}
              type="button"
              className="inline-flex text-light items-center px-4 p-1 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <FilterIcon className="-ml-3 h-3 w-3" aria-hidden="true" />
              {'-'}
              <span className="mr-1">Aktiv</span>
              <XIcon className="h-4 w-4 -mr-3" aria-hidden="true" />
            </button>
          ) : null}
        </div>
        {/* <GlobalBanner /> */}

        {mapCalendarEventsWithBanners(selectedAds, events)}
      </div>
    </>
  );
};

export default EventMainSection;
