/* This example requires Tailwind CSS v2.0+ */
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DropdownOption } from '../../lib/api/types/dropdown-option';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  placeholderText?: string;
  value?: string;
}

const DropdownInput: FunctionComponent<Props> = ({
  placeholderText = 'Alle',
  value,
  options,
  onSelect,
}) => {
  const [selected, setSelected] = useState(value || '');

  useEffect(() => {
    setSelected(value || '');
  }, [value]);

  const handleSelect = ({ target }: any) => {
    setSelected(target.textContent);

    if (target.textContent === placeholderText) {
      onSelect('');
      return;
    }

    onSelect(target.textContent);
  };

  return (
    <Menu as="div" className="min-w-1/2 relative inline-block text-left">
      <div>
        <Menu.Button className=" inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {selected || placeholderText}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          onClick={handleSelect}
          className="origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {placeholderText || 'Alle'}
                </div>
              )}
            </Menu.Item>
            {options.map((option) => {
              return (
                <Menu.Item key={option.value}>
                  {({ active }) => (
                    <div
                      id={`region-${option.value}`}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {option.label}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownInput;
