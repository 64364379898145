import { FunctionComponent } from 'react';
import { BANNER_TYPE } from '../../lib/api/enums/banner-type';
import { BannerAd } from '../../lib/api/types/banner-ad';
import Image from 'next/image';

interface Props {
  bannerAd: BannerAd | undefined;
}

const BannerAdComponent: FunctionComponent<Props> = ({ bannerAd }) => {
  if (!bannerAd) {
    return null;
  }

  return (
    <div
      draggable="false"
      className="flex justify-center rounded-md align-center mx-1 my-4"
    >
      {getProperImageComponent(bannerAd)}
    </div>
  );
};

function getProperImageComponent(ad: BannerAd) {
  const layout = { width: 0, height: 0 };

  switch (ad.bannerType) {
    case BANNER_TYPE.SMALL:
      layout.height = 250;
      layout.width = 1000;
      break;
    case BANNER_TYPE.MEDIUM:
      layout.height = 750;
      layout.width = 1000;
      break;
    case BANNER_TYPE.LARGE:
      layout.height = 1250;
      layout.width = 1000;
      break;
  }

  return (
    <div className="cursor-pointer">
      <a href={ad.callToActionUrl} target="_blank" rel="noreferrer">
        <Image
          // onClick={() => window.open(ad.callToActionUrl, '_blank')}
          alt={ad.altText}
          src={ad.imageUrl}
          width={layout.width}
          height={layout.height}
        />
      </a>
    </div>
  );
}

export default BannerAdComponent;
