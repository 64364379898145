import { XCircleIcon } from '@heroicons/react/outline';
import { format, parse } from 'date-fns';
import { FunctionComponent, useState, useCallback, useEffect } from 'react';

interface Props {
  name?: string;
  id?: string;
  value?: Date | null;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
}

const DatePicker: FunctionComponent<Props> = ({
  value,
  onChange,
  name,
  id,
  disabled,
}) => {
  const [formattedDate, setFormattedDate] = useState<string>('');

  useEffect(() => {
    if (!value) {
      setFormattedDate('');
      return;
    }

    setFormattedDate(format(value, 'yyyy-MM-dd'));
  }, [value]);

  const handleDateChange = (event: any): void => {
    setFormattedDate(event.target.value);

    const start = parse(event.target.value, 'yyyy-MM-dd', new Date());

    if (start instanceof Date && isNaN(start.getTime())) {
      return;
    }

    onChange(start);
  };

  const handleReset = (): void => {
    setFormattedDate('');
    onChange(null);
  };

  return (
    <div
      className={`flex items-center flex-between ${
        disabled ? 'opacity-25' : ''
      }`}
    >
      <input
        disabled={disabled}
        name={name}
        id={id}
        value={formattedDate}
        onChange={handleDateChange}
        type="date"
        className="mr-2 max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      />
      <div className="w-6">
        {formattedDate ? (
          <XCircleIcon
            className="w-5 h-5 text-primary"
            aria-hidden="true"
            onClick={handleReset}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DatePicker;
