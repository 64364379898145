import { Dialog, Transition } from '@headlessui/react';
import {
  FilterIcon as FilterIconOutline,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  defaultAgeRangeFilterOptions,
  defaultRegionFilterOptions,
} from '../lib/utility/calendar-event-filters';
import AppContext from './common/AppContext';
import DatePicker from './shared/DatePicker';
import DropdownInput from './shared/DropdownInput';

const FilterSection: FunctionComponent = () => {
  const [isReccuring, setIsRecurring] = useState(false);
  const [isEventOrganizer, setIsEventOrganizer] = useState(false)

  const {
    calendarEventFilter,
    setCalendarEventFilter,
    resetCalendarEventFilter,
    mobileFiltersOpen,
    setMobileFiltersOpen,
    desktopFiltersOpen,
  } = useContext(AppContext);

  const router = useRouter();

  useEffect(() => {
    setIsRecurring(router.pathname === '/wiederkehrende');
    setIsEventOrganizer(router.pathname === '/veranstalter');
  }, [router.pathname]);

  const handleAgeRangeSelect = ({ target }: any) => {
    const { checked, value } = target;
    const { ageRangeFilter } = calendarEventFilter;

    if (checked && !ageRangeFilter.includes(value)) {
      setCalendarEventFilter({
        ...calendarEventFilter,
        ageRangeFilter: [...ageRangeFilter, value],
      });
    }

    if (!checked && ageRangeFilter.includes(value)) {
      setCalendarEventFilter({
        ...calendarEventFilter,
        ageRangeFilter: ageRangeFilter.filter((ageRange) => ageRange !== value),
      });
    }
  };

  return (
    <div className="bg-white lg:shadow lg:rounded-md">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-xl font-bold text-gray-900">Filter</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form
                  onSubmit={(event) => event.preventDefault()}
                  className="mt-2 border-t border-gray-200"
                >
                  <div className="m-4 mt-5 ">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 mb-6">
                      <label
                        htmlFor="first-name"
                        className="block font-medium sm:mt-px sm:pt-2 text-gray-900"
                      >
                        Suche:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="flex items-center flex-center">
                          <input
                            value={calendarEventFilter.textSearchFilter}
                            onChange={({ target }) =>
                              setCalendarEventFilter({
                                ...calendarEventFilter,
                                textSearchFilter: target.value,
                              })
                            }
                            type="text"
                            className="mr-2 max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                          <div className="w-6">
                            {calendarEventFilter.textSearchFilter && (
                              <XCircleIcon
                                className="w-5 h-5 text-primary"
                                aria-hidden="true"
                                onClick={() =>
                                  setCalendarEventFilter({
                                    ...calendarEventFilter,
                                    textSearchFilter: '',
                                  })
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 mb-6 ${isReccuring || isEventOrganizer ? 'opacity-25' : ''
                        }`}
                    >
                      <label
                        htmlFor="startDate"
                        className="block font-medium sm:mt-px sm:pt-2 text-gray-900"
                      >
                        Von:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <DatePicker
                          disabled={isReccuring || isEventOrganizer}
                          name="startDate"
                          value={calendarEventFilter.startDateFilter}
                          onChange={(date) =>
                            setCalendarEventFilter({
                              ...calendarEventFilter,
                              startDateFilter: date,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={`sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 mb-6 ${isReccuring || isEventOrganizer ? 'opacity-25' : ''
                        }`}
                    >
                      <label
                        htmlFor="endDate"
                        className="block font-medium sm:mt-px sm:pt-2 text-gray-900"
                      >
                        Bis:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <DatePicker
                          name="endDate"
                          disabled={isReccuring || isEventOrganizer}
                          value={calendarEventFilter.endDateFilter}
                          onChange={(date) =>
                            setCalendarEventFilter({
                              ...calendarEventFilter,
                              endDateFilter: date,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 mb-6">
                      <label
                        htmlFor="region"
                        className="block font-medium sm:mt-px sm:pt-2 text-gray-900"
                      >
                        Region:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <DropdownInput
                          value={calendarEventFilter.region}
                          onSelect={(val) =>
                            setCalendarEventFilter({
                              ...calendarEventFilter,
                              region: val,
                            })
                          }
                          options={defaultRegionFilterOptions()}
                        />
                      </div>
                    </div>
                  </div>
                  <div key={'ageRange'} className="p-4 mb-12">
                    <h3 className="-mx-2 -my-6">
                      <div className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {'Altersstufen:'}
                        </span>
                      </div>
                    </h3>
                    <div className="pt-6 block">
                      <div className="space-y-4">
                        {defaultAgeRangeFilterOptions().map((option) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              onChange={handleAgeRangeSelect}
                              id={`mobile-ageRange__${option.value}`}
                              name={`mobile-ageRange__${option.value}`}
                              defaultValue={option.value}
                              type="checkbox"
                              checked={calendarEventFilter.ageRangeFilter.includes(
                                option.value
                              )}
                              className="h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary  checked:bg-primary"
                            />
                            <label
                              htmlFor={`mobile-ageRange__${option.value}`}
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mx-4 my-2 flex-wrap">
                    <div className="flex-shrink-0 my-2 opacity-75">
                      <button
                        onClick={resetCalendarEventFilter}
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        <XIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span>Zurücksetzen</span>
                      </button>
                    </div>
                    <div className="flex-shrink-0 my-2 mr-6">
                      <button
                        onClick={() => setMobileFiltersOpen(false)}
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        <FilterIconOutline
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span>Filtern</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <div
          className={`sticky top-10 max-w-7xl mx-auto mb-12 px-12 bg-white hidden ${desktopFiltersOpen ? 'lg:block' : ''
            }`}
        >
          <div className="relative z-10 flex items-baseline justify-between pt-3 pb-2 border-b border-gray-200">
            <h1 className="text-2xl font-md text-gray-800">Filter</h1>
          </div>

          <section aria-labelledby="products-heading" className="">
            <form onSubmit={(event) => event.preventDefault()} className="">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10">
                <div className="">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pr-2"
                    >
                      Suche:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center flex-center">
                        <input
                          value={calendarEventFilter.textSearchFilter}
                          onChange={({ target }) =>
                            setCalendarEventFilter({
                              ...calendarEventFilter,
                              textSearchFilter: target.value,
                            })
                          }
                          type="text"
                          className="mr-2 max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />
                        <div className="w-6">
                          {calendarEventFilter.textSearchFilter ? (
                            <XCircleIcon
                              className="w-5 h-5 text-primary"
                              aria-hidden="true"
                              onClick={() =>
                                setCalendarEventFilter({
                                  ...calendarEventFilter,
                                  textSearchFilter: '',
                                })
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 ${isReccuring || isEventOrganizer ? 'opacity-25' : ''
                      }`}
                  >
                    <label
                      htmlFor="startDate"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Von:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <DatePicker
                        disabled={isReccuring || isEventOrganizer}
                        name="startDate"
                        value={calendarEventFilter.startDateFilter}
                        onChange={(date) =>
                          setCalendarEventFilter({
                            ...calendarEventFilter,
                            startDateFilter: date,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 ${isReccuring || isEventOrganizer ? 'opacity-25' : ''
                      }`}
                  >
                    <label
                      htmlFor="endDate"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Bis:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <DatePicker
                        disabled={isReccuring || isEventOrganizer}
                        name="endDate"
                        value={calendarEventFilter.endDateFilter}
                        onChange={(date) =>
                          setCalendarEventFilter({
                            ...calendarEventFilter,
                            endDateFilter: date,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 mb-6">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Region:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <DropdownInput
                        value={calendarEventFilter.region}
                        onSelect={(val) =>
                          setCalendarEventFilter({
                            ...calendarEventFilter,
                            region: val,
                          })
                        }
                        options={defaultRegionFilterOptions()}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div key="ageRange" className="py-6">
                    <h3 className="-my-3 flow-root">
                      <div className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {'Altersstufen'}
                        </span>
                      </div>
                    </h3>
                    <div className="pt-6">
                      <div className="space-y-4">
                        {defaultAgeRangeFilterOptions().map((option) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              onChange={handleAgeRangeSelect}
                              id={`ageRange__${option.value}`}
                              name={`ageRange__${option.value}`}
                              defaultValue={option.value}
                              type="checkbox"
                              checked={calendarEventFilter.ageRangeFilter.includes(
                                option.value
                              )}
                              className="h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary checked:bg-primary accent-bg-primary"
                            />
                            <label
                              htmlFor={`ageRange__${option.value}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-end justify-end mb-4 opacity-75">
                    <div className="flex-shrink-0">
                      <button
                        onClick={resetCalendarEventFilter}
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        <FilterIconOutline
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span>Filter zurücksetzen</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
