import { DropdownOption } from '../api/types/dropdown-option';
import {
  DEFAULT_AGE_RANGE_OPTIONS,
  DEFAULT_REGION_OPTIONS,
} from './filter-default-values';

export function defaultAgeRangeFilterOptions(): DropdownOption[] {
  return DEFAULT_AGE_RANGE_OPTIONS.map((ageRange) => ({
    value: ageRange,
    label: ageRange,
    checked: true,
  }));
}

export function defaultRegionFilterOptions(): DropdownOption[] {
  return DEFAULT_REGION_OPTIONS.map((region) => ({
    value: region,
    label: region,
  }));
}
