import startOfToday from 'date-fns/startOfToday';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

export function composeEventDateAndTimeString(
  startDate: string,
  endDate: string
): string {
  const parsedStartDate = parseCalendarEventDate(startDate);
  const parsedEndDate = parseCalendarEventDate(endDate);

  const startDateString = format(parsedStartDate, 'dd.MM.yyyy, HH:mm');
  const endDateString = format(parsedEndDate, 'HH:mm');

  return `${startDateString} - ${endDateString} Uhr`;
}

export function composeEventDateString(
  startDate: string,
  endDate: string
): string {
  const parsedStartDate = parseCalendarEventDate(startDate);
  const parsedEndDate = parseCalendarEventDate(endDate);

  const startDateString = format(parsedStartDate, 'dd.MM.yyyy');
  const endDateString = format(parsedEndDate, 'dd.MM.yyyy');

  return `${startDateString} — ${endDateString}`;
}

export function parseCalendarEventDate(dateStr: string): Date {
  if (!dateStr) {
    throw new Error('no date');
  }

  return parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date());
}

export function eventExpired(dateStr: string): boolean {
  const now = new Date();
  now.setDate(now.getDate() - 3);

  return parseCalendarEventDate(dateStr) < now;
}

export function bannerExpired(endDateStr: string | null | undefined): boolean {
  if (!endDateStr) {
    return false;
  }

  const today = startOfToday();

  const endDate = parse(endDateStr, 'yyyyMMdd', today);

  return endDate < today;
}
