import { endOfDay } from 'date-fns';
import { CalendarEvent } from '../api/types/calendar-event';
import { CalendarEventFilter } from '../api/types/calendar-event-filter';
import { parseCalendarEventDate } from './date-helper';

export const handleFilters = (
  filters: CalendarEventFilter | null | undefined,
  events: CalendarEvent[]
): CalendarEvent[] => {
  if (!filters) {
    return events;
  }

  let filteredEvents = [...events];

  filteredEvents = filterByAgeRange(filters.ageRangeFilter, filteredEvents);
  filteredEvents = filterByText(filters.textSearchFilter, filteredEvents);
  filteredEvents = filterByStartDate(filters.startDateFilter, filteredEvents);
  filteredEvents = filterByEndDate(filters.endDateFilter, filteredEvents);
  filteredEvents = filterByRegion(filters.region, filteredEvents);

  return filteredEvents;
};

const filterByText = (
  text: string | null | undefined,
  calendarEvents: CalendarEvent[]
): CalendarEvent[] => {
  if (!text) {
    return calendarEvents;
  }

  return calendarEvents.filter((calendarEvent) => {
    return (
      calendarEvent.description.toLowerCase().includes(text.toLowerCase()) ||
      calendarEvent.title.toLowerCase().includes(text.toLowerCase())
    );
  });
};

const filterByStartDate = (
  date: Date | null | undefined,
  calendarEvents: CalendarEvent[]
): CalendarEvent[] => {
  if (!date) {
    return calendarEvents;
  }

  return calendarEvents.filter((calendarEvent) => {
    const parsedStartDate = parseCalendarEventDate(calendarEvent.startDate);

    return parsedStartDate >= date;
  });
};

const filterByAgeRange = (
  ageRanges: string[] | null | undefined,
  calendarEvents: CalendarEvent[]
): CalendarEvent[] => {
  if (!ageRanges) {
    return calendarEvents;
  }

  return calendarEvents.filter((event) => {
    const splittedAgeRange = event.ageRange.split('|');

    if (splittedAgeRange.includes('Alle')) {
      return true;
    }

    return splittedAgeRange.some((ageRange) => ageRanges.includes(ageRange));
  });
};

const filterByEndDate = (
  date: Date | null | undefined,
  calendarEvents: CalendarEvent[]
): CalendarEvent[] => {
  if (!date) {
    return calendarEvents;
  }

  return calendarEvents.filter((calendarEvent) => {
    const parsedStartDate = parseCalendarEventDate(calendarEvent.endDate);

    return parsedStartDate < endOfDay(date);
  });
};

const filterByRegion = (
  region: string,
  calendarEvents: CalendarEvent[]
): CalendarEvent[] => {
  if (!region) {
    return calendarEvents;
  }

  return calendarEvents.filter((calendarEvent) => {
    return calendarEvent.region === region;
  });
};
