import { BANNER_TYPE } from '../api/enums/banner-type';
import { SPONSOR_TYPE } from '../api/enums/sponsor-type';
import { BannerAd } from '../api/types/banner-ad';
import { bannerExpired } from './date-helper';

export function selectBannerAds(
  ads: BannerAd[],
  eventCount: number
): BannerAd[] {
  if (eventCount <= 0) {
    return [];
  }

  // Additionally to the building process we also filter the banner ad list
  // because a banner ad can expire between two building processes
  const filteredExpiredBanners = ads.filter((ad) => !bannerExpired(ad.endDate));

  const availableAdSlots = calculateAvailableAdSlots(eventCount);

  const smallAds = handleAdsByBannerType(
    filteredExpiredBanners,
    BANNER_TYPE.SMALL,
    availableAdSlots
  );

  const mediumAds = handleAdsByBannerType(
    filteredExpiredBanners,
    BANNER_TYPE.MEDIUM,
    availableAdSlots
  );
  const largeAds = handleAdsByBannerType(
    filteredExpiredBanners,
    BANNER_TYPE.LARGE,
    availableAdSlots
  );

  return shuffleBannerAds([...smallAds, ...mediumAds, ...largeAds]);
}

const calculateAvailableAdSlots = (eventsCount: number): number => {
  const ratio = Number.parseInt(process.env.EVENT_TO_AD_RATIO || '4');

  return Math.floor(eventsCount / ratio);
};

const handleAdsByBannerType = (
  ads: BannerAd[],
  bannerType: BANNER_TYPE,
  totalAvailableAdSlots: number
): BannerAd[] => {
  if (!ads?.length) {
    return [];
  }

  const filteredAds = ads.filter((ad) => ad.bannerType === bannerType);

  // if just one ad slot is available we always take the small banner type
  if (totalAvailableAdSlots === 1 && bannerType === BANNER_TYPE.SMALL) {
    return allocateAdsToAdSlots(filteredAds, 1);
  }

  const slotRatio = retrieveAdSlotRatioByBannerType(bannerType);

  const availableSlots = Math.floor((slotRatio * totalAvailableAdSlots) / 100);

  return allocateAdsToAdSlots(filteredAds, availableSlots);
};

const allocateAdsToAdSlots = (
  ads: BannerAd[],
  availableSlots: number
): BannerAd[] => {
  if (!ads.length || !availableSlots) {
    return [];
  }

  const allocatedAdSlots: BannerAd[] = [];
  let slotsLeft = availableSlots;

  while (slotsLeft > 0) {
    for (let i = 0; i < ads.length; i++) {
      if (slotsLeft <= 0) {
        break;
      }

      const ad = ads[i];
      const rndNumber = Math.ceil(Math.random() * 100);

      switch (ad.sponsorType) {
        case SPONSOR_TYPE.BRONZE:
          if (rndNumber <= retrieveSponsorTypeRatio(SPONSOR_TYPE.BRONZE)) {
            allocatedAdSlots.push(ad);
            slotsLeft--;
          }
          break;
        case SPONSOR_TYPE.SILVER:
          if (
            rndNumber > retrieveSponsorTypeRatio(SPONSOR_TYPE.SILVER) &&
            rndNumber <= retrieveSponsorTypeRatio(SPONSOR_TYPE.GOLD)
          ) {
            allocatedAdSlots.push(ad);
            slotsLeft--;
          }
        case SPONSOR_TYPE.GOLD:
          if (rndNumber > retrieveSponsorTypeRatio(SPONSOR_TYPE.SILVER)) {
            allocatedAdSlots.push(ad);
            slotsLeft--;
          }
          break;
      }
    }
  }

  return allocatedAdSlots;
};

const shuffleBannerAds = (bannerAds: BannerAd[]): BannerAd[] => {
  let currentIndex = bannerAds.length;
  let randomIndex: number;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [bannerAds[currentIndex], bannerAds[randomIndex]] = [
      bannerAds[randomIndex],
      bannerAds[currentIndex],
    ];
  }

  return bannerAds;
};

const retrieveAdSlotRatioByBannerType = (bannerType: BANNER_TYPE): number => {
  switch (bannerType) {
    case BANNER_TYPE.SMALL:
      return Number.parseFloat(process.env.AD_SLOT_RATIO_SMALL || '50');
    case BANNER_TYPE.MEDIUM:
      return Number.parseFloat(process.env.AD_SLOT_RATIO_MEDIUM || '35');
    case BANNER_TYPE.LARGE:
      return Number.parseFloat(process.env.AD_SLOT_RATIO_LARGE || '15');
    default:
      return 0;
  }
};

const retrieveSponsorTypeRatio = (sponsorType: SPONSOR_TYPE): number => {
  switch (sponsorType) {
    case SPONSOR_TYPE.BRONZE:
      return Number.parseInt(process.env.SPONSOR_TYPE_BRONZE_RATIO || '15');
    case SPONSOR_TYPE.SILVER:
      return Number.parseInt(process.env.SPONSOR_TYPE_SILVER_RATIO || '35');
    case SPONSOR_TYPE.GOLD:
      return Number.parseInt(process.env.SPONSOR_TYPE_GOLD_RATIO || '50');
    default:
      return 0;
  }
};
